<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc">รายละเอียด</p>
            <!-- skeleton  -->
            <b-overlay
              :show="overlayFlag"
              :spinner-variant="this.$overlayVariant"
              :spinner-type="this.$overlayType"
              :rounded="this.$overlayRounded"
            >
              <div class="row">
                <!-- <div class="row align-items-center"> -->
                <div class="col-md-3 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="createdBy">ผู้ส่ง:</label>
                    <input
                      disabled
                      id="createdBy"
                      v-model="contact.createdBy"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="createdAt">วันที่-เวลาที่ส่ง:</label>
                    <input
                      disabled
                      id="createdAt"
                      v-model="contact.createdAt"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="contactType">ประเภทการส่ง:</label>
                    <input
                      disabled
                      id="contactType"
                      v-model="contact.contactType"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="subject">เรื่อง:</label>
                    <div class="input-group">
                      <input
                        disabled
                        id="subject"
                        v-model="contact.subject"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="filterType">รูปแบบ:</label>
                    <input
                      disabled
                      id="filterType"
                      v-model="contact.filterType"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="branchId">สาขา:</label>
                    <input
                      disabled
                      id="branchId"
                      v-model="contact.branchId"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="message">ข้อความ:</label>
                    <div class="input-group">
                      <textarea
                        disabled
                        id="message"
                        v-model="contact.message"
                        class="form-control"
                        placeholder=""
                      ></textarea>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </div>
              <br />
            </b-overlay>
          </div>
        </div>

        <!-- ประวัติการส่ง SMS  -->
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc">ประวัติการส่ง SMS</p>
            <!-- skeleton  -->

            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton-table
                  :rows="5"
                  :columns="6"
                  :table-props="{ bordered: false, striped: true }"
                  animation="throb"
                ></b-skeleton-table>
              </template>
              <!-- <div class="row mt-4">
          
                <div class="col-sm-12 col-md-12">
                  <div class="row align-items-center">
                    <div class="col-sm-12 col-md-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-block align-items-center">
                          แสดงผล
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            @input="handlePageChange"
                          ></b-form-select
                          >&nbsp; รายการ
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-1"></div>
                  </div>
                </div>
               
              </div> -->
              <!-- Table -->

              <div class="table-responsive mb-0">
                <b-table
                  id="table-transition-userList"
                  :items="branchContact"
                  :fields="fields"
                  responsive="true"
                  :per-page="perPage"
                  :current-page="1"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :select-mode="selectMode"
                >
                  <template #cell(smsResult)="branchContact">
                    <span
                      class="badge bg-danger font-size-12 ms-2"
                      v-if="branchContact.item.smsResult === 0"
                    >
                      ส่งไม่สำเร็จ
                    </span>
                    <span
                      class="badge bg-success font-size-12 ms-2"
                      v-if="branchContact.item.smsResult === 1"
                    >
                      ส่งสำเร็จ
                    </span>
                  </template>

                  <!-- edit table -->
                  <template v-slot:cell(action)="rowContact">
                    <router-link
                      class="px-2 text-primary"
                      v-b-tooltip.hover
                      title="Edit"
                      :to="{
                        name: 'detailContact',
                        params: {
                          groupContactId: rowContact.item.groupContactId,
                        },
                      }"
                    >
                      <i class="uil uil-pen font-size-18"></i>
                    </router-link>
                  </template>

                  <!-- End edit table -->
                </b-table>
              </div>
              <div class="row">
                <!-- <div class="col">
                  แสดงผล {{ startIndex }} ถึง {{ endIndex }} จาก
                  {{ totalRecord }} รายการ
                </div> -->

                <!-- <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                     
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecord"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div> -->
                <br />
                <hr />
                <br />
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>

    <!-- end col -->
  </Layout>
</template>
<script>
// import {
//   required,
//   // numeric
//   decimal,
//   maxValue,
//   minValue,
//   maxLength,
// } from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
// import Multiselect from "vue-multiselect";

/**
 * Form validation component
 */
export default {
  mounted() {},
  components: {
    Layout,
    PageHeader,
  },
  page: {
    title: appConfig.groupContacts,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      message: "โปรดตรวจสอบข้อมูล",
      title: "รายละเอียดการส่งข้อความ",
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },
        {
          text: appConfig.groupContacts,
          href: "/contactMessage",
        },
        {
          text: "รายละเอียดการส่งข้อความ",
          active: true,
        },
      ],
      branchGroupContact: [],
      branchContact: [],
      selectedUsers: [],
      isSelectedAll: false,
      from: [],
      selectMode: "multi",
      selected: [],

      loading: false,
      localDataBranch: [],
      rowContact: [],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      filter: [],
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",
      overlayFlag: false,
      groupContactId: this.$route.params.groupContactId,
      idBase: this.$route.params.groupContactId,
      options: [],
      contact: {
        branchId: "",
        contactType: "",
        subject: "",
        message: "",
        filterType: "",
        templateId: "",
        vehicleId: "",
        cusId: "",
        mobilePhone: "",
        createdAt: "",
        createdBy: "",
      },

      submitform: false,
      fields: [
        {
          key: "contactId",
          label: "รหัสส่ง",
        },
        {
          key: "cusName",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "contactInfo",
          sortable: true,
          label: "เบอร์โทรศัพท์",
        },
        {
          key: "licensePlate",
          label: "ทะเบียนรถ",
          sortable: true,
        },

        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "smsResult",
          sortable: true,
          label: "สถานะ",
        },
      ],
    };
  },

  validations: {},
  created() {
    // console.log(this.idBase);
    this.getLocalData();
    this.getDataUpdate();
  },
  methods: {
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;

      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item);
      });
      this.localDataBranch = localDataBranch;

      // console.log(this.localDataBranch);
    },
    //! atob แปลงเป็นเลข
    //! btoa แปลงเป็น code
    getDataUpdate: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/group-contact/show", {
          params: {
            groupContactId: atob(this.idBase),
          },
        })
        .then((response) => {
          this.branchGroupContact = response.data.branchGroupContact;
          this.branchContact = response.data.branchContact;

          this.contact.createdBy = response.data.branchGroupContact.createdBy;
          this.contact.createdAt = response.data.branchGroupContact.createdAt;
          this.contact.contactType =
            response.data.branchGroupContact.contactType;
          this.contact.subject = response.data.branchGroupContact.subject;
          this.contact.filterType = response.data.branchGroupContact.filterType;
          this.contact.branchId = response.data.branchGroupContact.branchName;
          this.contact.message = response.data.branchGroupContact.message;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.$router.push({ name: "main-user" });
        });
    },

    updateWage: function () {
      // this.overlayFlag = true;

      useNetw
        .put("api/wage/update", {
          branchId: this.form.branchId.branchId,
          wageId: this.wageId,
          wageCode: this.form.wageCode != null ? this.form.wageCode : "",
          nameTh: this.form.nameTh != null ? this.form.nameTh : "",
          nameEn: this.form.nameEn != null ? this.form.nameEn : "",
          useInGs: this.form.useInGs != null ? this.form.useInGs : "0",
          useInBp: this.form.useInBp != null ? this.form.useInBp : "0",
          price: this.form.price != null ? this.form.price : "",
          maxDcPer: this.form.maxDcPer != null ? this.form.maxDcPer : "",
          maxDcAmount:
            this.form.maxDcAmount != null ? this.form.maxDcAmountd : "",
          wageType:
            this.form.wageType.name != null ? this.form.wageType.name : "",
          workDuration:
            this.form.workDuration != null ? this.form.workDuration : "",
        })

        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "branch-wages" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowUser = filteredItems.length;
      this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
  },
};
</script>

<style lang="scss" scoped></style>
